import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';
import { PerspectiveCamera, AnimationMixer } from 'three';
import useGetAvatarModel from '../../services/avaturn/queries/useGetAvatarModel';
import { AvaturnAvatar } from './avatar.types';

import idlePose from '../../assets/animations/idle-pose.glb';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Spinner } from 'reactstrap';
import './AvatarImage.css';
import { COLORS } from '@/constant/COLORS';
import { PiUserCircleDuotone } from 'react-icons/pi';

const Model = ({ url }: { url: string }) => {
  const [model, setModel] = useState<GLTF>();
  const poseAnimation = useLoader(GLTFLoader, idlePose) as GLTF;
  const mixer = useRef<AnimationMixer | null>(null);

  useEffect(() => new GLTFLoader().load(url, setModel), [url]);
  useEffect(() => {
    if (model && model.scene && poseAnimation.scene && poseAnimation.animations.length > 0) {
      mixer.current = new AnimationMixer(model.scene);
      const clip = poseAnimation.animations[0];
      const action = mixer.current.clipAction(clip);
      action.play();
    }
  }, [model, poseAnimation]);

  useFrame((_, delta) => {
    if (mixer.current) {
      mixer.current.update(delta);
    }
  });

  if (!model) return null;

  return <primitive object={model.scene} />;
};

const FixedCamera = () => {
  const { set } = useThree();
  useEffect(() => {
    const camera = new PerspectiveCamera(60, 1, 0.1, 1000);
    camera.setFocalLength(100);
    camera.position.set(-0.75, 1.7, 1.42);
    camera.lookAt(0, 1.68, 0);
    camera.rotation.y = -0.5;

    set({ camera });
  }, [set]);

  return null;
};

const AvatarRender = ({ userId, className: _className, round, avaturnUser }: AvaturnAvatar) => {
  // we have to check the model url because we have no way of handling the error (if model is 404 or invalid) on the useGLTF hook on the Model component
  const { isError: isFetchModelError, isLoading: isLoadingModel } = useGetAvatarModel({
    url: avaturnUser.modelUrl || '',
  });

  if (isFetchModelError)
    <div
      className="image-container"
      style={{
        borderColor: COLORS.violetLight,
        borderWidth: '5px',
        borderStyle: 'solid',
      }}
    >
      <PiUserCircleDuotone />
    </div>;

  return (
    <div>
      <div>
        <div
          style={{
            position: 'relative',
            borderRadius: round ? '100%' : '0',
            overflow: 'hidden',
            objectFit: 'cover',
          }}
        >
          {isLoadingModel && <Spinner size="12" />}

          <div
            className="image-container"
            style={{
              borderColor: COLORS.violetLight,
              borderWidth: '5px',
              borderStyle: 'solid',
            }}
          >
            <Canvas
              style={{
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: round ? '100%' : '0%',
                objectFit: 'cover',
              }}
              itemID={userId}
            >
              <ambientLight intensity={0.1} />

              {!isFetchModelError && !isLoadingModel && avaturnUser.modelUrl && <Model url={avaturnUser.modelUrl} />}

              <FixedCamera />
              <pointLight position={[-8, 7, 10]} intensity={240} />
              <pointLight position={[-2, 2, 12]} intensity={160} />
            </Canvas>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvatarRender;
